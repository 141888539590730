










































































































import { Inject } from 'inversify-props';
import {
    Component,
    Mixins,
    Provide,
    ProvideReactive,
    Watch,
} from 'vue-property-decorator';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DayChanger from '@/modules/common/components/day-changer.vue';
import Day from '@/modules/common/types/day.type';
import ClipText from '@/modules/common/filters/clip-text.filter';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import PromotionsFiltersMixin from '../mixins/promotions-filter.mixin';
import PromotionsHistoryService, { PromotionsHistoryServiceS } from '../promotion-history.service';
import PromotionsHistoryTable from './history/table.vue';
import PromotionsHistoryTooltip from './history/tooltip.vue';

const ZERO_DAY = '-  0  -';
const formatter = new Intl
    .DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

@Component({
    components: {
        ModalWrapper,
        CustomGraph,
        CustomSelect,
        PromotionsHistoryTable,
        PromotionsHistoryTooltip,
        DayChanger,
    },
    filters: {
        ClipText: (v: string) => ClipText(v, 25),
    },
})
export default class PromotionsHistory extends Mixins(PromotionsFiltersMixin) {
    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(PromotionsHistoryServiceS)
    private promotionsHistoryService!: PromotionsHistoryService;

    @Inject(DocumentFiltersServiceS)
    private documentFilterService!: DocumentFiltersService;

    @ProvideReactive('filters')
    public filters = {
        provider: null as string | null,
        program: null as string | null,
    };

    @ProvideReactive('mainHotelId')
    public get mainHotelId() {
        return +this.$route.params.hotelId
            || this.userService.currentHotelId;
    }

    @ProvideReactive('daysAgo')
    public daysAgo: number | null = null;

    @ProvideReactive('lastScanDay')
    private get lastScanDay() {
        const { provider, program } = this.filters;

        if (!provider || !program) return 0;

        return this.promotionsHistoryService
            .getLastScanDay(this.day, provider, program);
    }

    @ProvideReactive('isDealProgram')
    private get isDealProgram() {
        if (!this.filters.program) return false;

        return this.filters.program.includes('percentage');
    }

    @ProvideReactive('hiddenGraphs')
    public hiddenGraphs: { [hotelId: number]: boolean } = {};

    @ProvideReactive('tooltipFocusElement')
    tooltipFocusElement: HTMLElement | null = null;

    @ProvideReactive('tooltipDay')
    tooltipDay: Day | null = null;

    @ProvideReactive('isLoading')
    public isLoading = false;

    public get day() {
        return +this.$route.params.day || 1;
    }

    public get scanDate() {
        const { month, year } = this.documentFilterService;
        const day = this.lastScanDay - (this.daysAgo || 0) - 1;

        return formatter.format(new Date(year, month, day));
    }

    public get programFilter() {
        return this.filters.program;
    }

    public set programFilter(value: string | null) {
        const { program: oldValue } = this.filters;
        const newValueIsPercentage = !!value && value.includes('percentage');
        const oldValueIsPercentage = !!oldValue && oldValue.includes('percentage');

        if (newValueIsPercentage !== oldValueIsPercentage) {
            this.daysAgo = null;
        }

        this.filters.program = value;
    }

    public set providerFilter(value: string | null) {
        const { provider: oldValue } = this.filters;
        this.filters.provider = value;

        if (oldValue !== value) {
            this.filters.program = this.programItems[0] && this.programItems[0].value;
        }
    }

    public get providerFilter() {
        return this.filters.provider;
    }

    public get graphAttrs() {
        const { days } = this.documentFiltersService;
        const labels = days
            .map((_: any, index: number) => {
                if (index === 0) {
                    return `${index}`;
                }

                const day = index;
                return String(day).length === 1
                    ? `-0${day}`
                    : -day;
            })
            .reverse();
        return {
            chartData: {
                labels,
                datasets: this.daysData,
            },
            options: this.graphOptions,
            haveFixedTooltip: true,
            isTooltip: true,
        };
    }

    private get daysData() {
        const { data } = this.promotionsService;
        const { days } = this.documentFiltersService;
        const { provider, program } = this.filters;

        if (!data || !provider || !program) return [{}];

        const hotelColor = this.hotelsService.getHotelsGraphColor();

        const generatePercentData = (hotelId: number) => days
            .map((_, daysAgo) => {
                const programData = this.promotionsHistoryService
                    .getProgramData(this.lastScanDay, daysAgo, provider, hotelId, program);

                if (!programData || !programData.status) {
                    return null;
                }

                return programData.percentage;
            })
            .reverse();

        const generateHotelList = (hotelId: number, index: number) => days
            .map((_, daysAgo) => {
                const programData = this.promotionsHistoryService
                    .getProgramData(this.lastScanDay, daysAgo, provider, hotelId, program);

                if (!programData || !programData.status) {
                    return null;
                }

                return index + 1;
            })
            .reverse();

        const getHotelColor = (hotelId: number) => {
            if (this.hiddenGraphs[hotelId] || this.isLoading) return 'transparent';

            return +hotelId === this.mainHotelId
                ? CURRENT_HOTEL_GRAPH_COLOR
                : hotelColor[hotelId];
        };

        return data.hotels
            .map((hotelId, index) => ({
                borderColor: getHotelColor(hotelId),
                pointRadius: (this.hiddenGraphs[hotelId] || this.isLoading) ? 0 : undefined,
                data: this.isDealProgram
                    ? generatePercentData(hotelId)
                    : generateHotelList(hotelId, index),
            }));
    }

    private get graphOptions() {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    cubicInterpolationMode: 'monotone',
                    lineTension: 0,
                },

                point: {
                    radius: 4,
                },
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },

            scales: this.graphScalesOptions,
        };
    }

    public get hotelList() {
        const { data } = this.promotionsService;

        if (!data) return [];

        return data.hotels.map(hotelId => ({
            id: hotelId,
            label: this.hotelsService.getHotelName(hotelId),
            isMain: hotelId === this.mainHotelId,
        }));
    }

    private get graphScalesOptions() {
        return {
            xAxes: [{
                gridLines: {
                    display: true,
                    borderDash: [0, 1],
                    offsetGridLines: true,
                    color: '#ECF1F5',
                },
            }],
            yAxes: [{
                gridLines: {
                    display: true,
                    offsetGridLines: true,
                    borderDash: [0, 4],
                    color: '#ECF1F5',
                    zeroLineWidth: 0,
                },
                ticks: {
                    autoSkip: true,
                    padding: 10,
                    width: 10,
                    callback: (v: string) => {
                        if (this.isDealProgram) return v;

                        return '';
                    },
                },
                offset: true,
            }],
        };
    }

    public get programItems() {
        const { data } = this.promotionsService;
        const { provider } = this.filters;

        if (!data || !provider) return [];

        const promotionsList = data.promotions[provider];

        if (!promotionsList) return [];

        return promotionsList.map(value => ({
            name: this.promotionsService.getProgramLabel(provider, value),
            value,
        }));
    }

    private setDefaultValuesForFilter() {
        this.filters.provider = this.provider;
        this.filters.program = this.programView
            || (this.programItems[0] && this.programItems[0].value);
    }

    @Watch('day')
    private async loadHistoryData() {
        await this.promotionsService.loading
            .whenLoadingFinished();

        if (!this.filters.provider) {
            this.setDefaultValuesForFilter();
        }

        try {
            this.isLoading = true;
            await this.promotionsHistoryService
                .loadTrendData(this.day);
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    public beforeMount() {
        this.loadHistoryData();
    }

    public mounted() {
        this.setDefaultValuesForFilter();
    }

    @Provide('toggleGraph')
    public toggleGraph(hotelId: number) {
        if (this.hiddenGraphs[hotelId]) {
            this.hiddenGraphs = {
                ...this.hiddenGraphs,
                [hotelId]: false,
            };
            return;
        }

        this.hiddenGraphs = {
            ...this.hiddenGraphs,
            [hotelId]: true,
        };
    }

    public setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    public setTooltipDay(label: string) {
        if (label === ZERO_DAY) {
            this.tooltipDay = 0 as Day;
            return;
        }

        this.tooltipDay = (label
            ? parseInt(label, 10)
            : null) as Day;

        this.tooltipDay = -this.tooltipDay as Day;
    }

    public setDay(label: string) {
        this.daysAgo = -parseInt(label, 10);
    }
}
