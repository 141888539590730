import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';
import PromotionsHistoryService, { PromotionsHistoryServiceS } from '../promotion-history.service';

export interface HistoryFilters {
    program?: string;
    provider?: string;
}

@Component
export default class HistoryTableMixin extends Vue {
    @Inject(PromotionsServiceS)
    protected promotionsService!: PromotionsService;

    @Inject(PromotionsHistoryServiceS)
    protected promotionsHistoryService!: PromotionsHistoryService;

    @Inject(HotelsServiceS)
    protected hotelsService!: HotelsService;

    protected getTableData(currentDay: number, daysAgo: number, mainHotelId: number, filters: HistoryFilters) {
        if (!filters) return [];
        const { program, provider } = filters;
        const { data } = this.promotionsService;

        if (!data || !program || !provider) return [];

        const isDealProgram = program.includes('percentage');
        const palette = this.hotelsService.getHotelsGraphColor();

        const list = data.hotels
            .map(hotelId => {
                const name = this.hotelsService
                    .getHotelName(hotelId) || hotelId;

                const programLabel = this.promotionsService
                    .getProgramLabel(provider, program);

                const isMain = hotelId === mainHotelId;
                const color = isMain
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : palette[hotelId];

                const programData = this.promotionsHistoryService
                    .getProgramData(currentDay, daysAgo, provider, hotelId, program);

                const programStatus = programData
                    ? programData.status
                    : false;

                const percent = programData && programStatus
                    ? programData.percentage
                    : 0;

                return {
                    id: hotelId,
                    name,
                    program: programLabel,
                    programStatus,
                    rank: 0,
                    percent,
                    color,
                    isMain,
                };
            });

        if (isDealProgram) {
            list.sort((a, b) => b.percent! - a.percent!);
            list.forEach((_, index) => {
                const row = list[index];
                list[index].rank = (row.programStatus && row.percent! > 0)
                    ? index + 1
                    : '' as unknown as number;
            });
        } else {
            list.reverse();
        }

        return list;
    }
}
