







import { Component, Vue } from 'vue-property-decorator';
import PromotionsHistory from '../components/promotions-history.vue';

@Component({
    components: {
        PromotionsHistory,
    },
})
export default class PromotionsHistoryPage extends Vue {
}
